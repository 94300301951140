import { apiEmbedTextRequest } from './api';
import { DEFAULT_CONFIG } from '../config';

export const embedText = async (text) => {
  const config = DEFAULT_CONFIG;

  // TODO: take model as a parameter defaulting to this value
  const body = {
    model: config.models.embed['all-minilm-l6-v2'].api_name,
    input: text,
  };

  try {
    const response = await apiEmbedTextRequest('/', {
      method: 'POST',
      requireAuth: true,
      body,
    });

    const messageId = response.message_id; // Assuming the response contains an ID for polling
    return messageId;
  } catch (error) {
    throw new Error(`Error in embedding request: ${error.message}`);
  }
};


export const pollForResult = async (messageId) => {
  const intervalTime = 2000; // Poll every 2 seconds

  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        const response = await apiEmbedTextRequest(`/${messageId}`, { method: 'GET' });

        if (response.status === "complete") {
          clearInterval(interval);
          resolve(response); // Resolve the promise with the final response
        }
      } catch (error) {
        clearInterval(interval);
        reject(new Error(`Error polling for result: ${error.message}`)); // Reject on error
      }
    }, intervalTime);
  });
};
