// src/components/auth/Signup.js
import React, { useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { Form, Button, Message } from 'semantic-ui-react';

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    try {
      const { isSignUpComplete } = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });
      if (isSignUpComplete) {
        setSuccess(true);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h2>Sign Up</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          label='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <Form.Input
          label='Email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Form.Input
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Form.Input
          label='Confirm Password'
          type='password'
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
          required
        />
        {error && <Message negative>{error}</Message>}
        {success && <Message positive>Signup successful! Please confirm your email.</Message>}
        <Button type='submit'>Sign Up</Button>
      </Form>
    </div>
  );
};

export default Signup;
