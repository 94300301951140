import React from 'react';
import { Item } from 'semantic-ui-react';
import CollectionItem from './CollectionItem';

const CollectionItems = ({ items, collectionId }) => {
  return (
    <Item.Group divided>
      {items.map((item, index) => (
        <CollectionItem key={item.id} item={item} collectionId={collectionId} />
      ))}
    </Item.Group>
  );
};

export default CollectionItems;
