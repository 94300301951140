import { apiInstructRequest } from './api';
import { DEFAULT_CONFIG } from '../config';

export const instruct = async (chat = {}, modelName = "qwen2-0.5b-instruct") => {
  const config = DEFAULT_CONFIG;

  // TODO: take model as a parameter defaulting to this value
  const body = {
    model: config.models.instruct[modelName].api_name,
    messages: chat,
  }

  try {
    const response = await apiInstructRequest('/', {
      method: 'POST',
      requireAuth: true,
      body,
    });

    const messageId = response.message_id; // Assuming the response contains an ID for polling
    return messageId;
  } catch (error) {
    throw new Error(`Error in instruct request: ${error.message}`);
  }
};


export const pollForResult = async (messageId) => {
  const intervalTime = 2000; // Poll every 2 seconds

  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        const response = await apiInstructRequest(`/${messageId}`, { method: 'GET' });

        if (response.status === "complete") {
          clearInterval(interval);
          resolve(response);
        } else if (response.status === "error") {
          console.error("response.status=error", response);
          clearInterval(interval);
          resolve(response)
        }
      } catch (error) {
        clearInterval(interval);
        reject(new Error(`Error polling for result: ${error.message}`)); // Reject on error
      }
    }, intervalTime);
  });
};
