import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { createCollection } from '../services/collection'; // Import the createCollection function

const CreateCollection = () => {
  const [name, setName] = useState('');
  const [embeddingSize, setEmbeddingSize] = useState(128); // Default embedding size

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newCollection = await createCollection(name, embeddingSize);
      console.log('Created Collection:', newCollection);
      // Redirect or update state as necessary
    } catch (error) {
      console.error('Error creating collection:', error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>Collection Name</label>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder='Enter collection name'
          required
        />
      </Form.Field>
      <Form.Field>
        <label>Embedding Size</label>
        <input
          type="number"
          value={embeddingSize}
          onChange={(e) => setEmbeddingSize(e.target.value)}
          placeholder='Enter embedding size'
          required
        />
      </Form.Field>
      <Button type='submit'>Create Collection</Button>
    </Form>
  );
};

export default CreateCollection;
