import {
  signIn,
  signOut,
  signUp,
  fetchAuthSession,
  fetchUserAttributes
} from 'aws-amplify/auth';

// User login
export async function login(username, password) {
  try {
    const { isSignedIn } = await signIn({ username, password });
    return isSignedIn;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
}

// User logout
export async function logout() {
  try {
    await signOut();
  } catch (error) {
    console.error('Logout failed:', error);
  }
}

// User signup
export async function signup(username, email, password) {
  try {
    const { isSignUpComplete } = await signUp({
      username,
      password,
      options: {
        userAttributes: { email }, // Other custom attributes can be added here
      },
    });
    return isSignUpComplete;
  } catch (error) {
    console.error('Signup failed:', error);
    throw error;
  }
}

// Get current user
export async function getCurrentUser() {
  try {
    const user = await fetchUserAttributes();
    return user;
  } catch (error) {
    console.error('Failed to get current user:', error);
    return null;
  }
}

// Get current user token
export async function getCurrentUserToken() {
  try {
    const { tokens } = await fetchAuthSession();
    console.log("tokens: ", tokens);
    return tokens.accessToken; // Access the token from the session
  } catch (error) {
    console.error('Failed to get user token:', error);
    throw error;
  }
}

// get an auth header for this user
export const getAuthHeader = async () => {
  try {
    const bearerToken = await getCurrentUserToken();

    return {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json', // You can include other headers if needed
    };
  } catch (error) {
    console.error('Failed to get authentication header:', error);
    throw error; // Re-throw the error for further handling
  }
};
