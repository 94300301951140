import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Comment, Form, Button, Segment, Message, TextArea } from 'semantic-ui-react';
import { instruct, pollForResult } from '../../services/instruct';
import ChatMessage from './ChatMessage';
import EditableSystemMessage from './EditableSystemMessage';
import NestedComment from './NestedComment'; // Newly created component
import { v4 as uuidv4 } from 'uuid'; // For unique IDs

const systemMessage = {
  id: uuidv4(),
  role: "system",
  content: "you're a buddy old pal, old friend! Tell me good news.",
  created: new Date().toLocaleString(),
}

const InstructInteraction = () => {
  const { modelType, modelName } = useParams(); // Extract type and name from the URL params
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([systemMessage]); // Initialize as empty; system message will be added on mount
  const [loading, setLoading] = useState(false); // Loading state for the submit button
  const [error, setError] = useState(null); // Global error state
  const [isEditingSystem, setIsEditingSystem] = useState(false); // State for editing system prompt
  const [editedSystemContent, setEditedSystemContent] = useState(''); // State for edited system prompt
  const [replyToMessageId, setReplyToMessageId] = useState(null); // State for replying to a specific message
  const [messageMap, setMessageMap] = useState(new Map()); // Map for quick message lookup
  const [renderMode, setRenderMode] = useState('linear'); // 'linear' or 'nested'

  // Helper Functions

  // Function to get all messages
  const getAllMessages = () => {
    return messages;
  };

  // Function to get the last five messages, ensuring the system prompt is included
  const getLastFiveMessages = () => {
    // Always include the system prompt (assumed to be the first message)
    const systemPrompt = messages.find(msg => msg.role === 'system');
    const lastFive = messages.slice(-5);
    return systemPrompt ? [systemPrompt, ...lastFive] : lastFive;
  };

  // Function to get context messages for replying to a specific message
  const getContextMessagesForReply = (messageId) => {
    const context = [];
    const localMap = new Map(messages.map(msg => [msg.id, msg]));

    let currentId = messageId;

    while (currentId) {
      const currentMessage = localMap.get(currentId);
      if (currentMessage) {
        context.unshift(currentMessage); // Add to the beginning
        currentId = currentMessage.parentId;
      } else {
        break; // If message not found, stop traversal
      }
    }

    // Ensure the system prompt is included
    const systemPrompt = messages.find(msg => msg.role === 'system');
    if (systemPrompt && !context.includes(systemPrompt)) {
      context.unshift(systemPrompt);
    }

    return context;
  };

  // Edit the system prompt
  const handleEditSystem = () => {
    setIsEditingSystem(true);
  };

  const handleSaveSystem = () => {
    setMessages((prev) =>
      prev.map((msg) =>
        msg.role === 'system'
          ? { ...msg, content: editedSystemContent, created: new Date().toISOString() }
          : msg
      )
    );
    setIsEditingSystem(false);
  };

  const handleCancelEditSystem = () => {
    const systemMsg = messages.find(msg => msg.role === 'system');
    if (systemMsg) {
      setEditedSystemContent(systemMsg.content);
    }
    setIsEditingSystem(false);
  };

  // Handle reply action
  const handleReply = (messageId) => {
    setReplyToMessageId(messageId);
  };

  // Send Message Handler
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!message.trim()) return; // Prevent sending empty messages

    setLoading(true);
    setError(null);

    const userMessage = {
      id: uuidv4(),
      role: "user",
      content: message.trim(),
      created: new Date().toISOString(),
      username: "You",
      parentId: replyToMessageId, // Link to the message being replied to
    };

    const modelMessage = {
      id: uuidv4(),
      role: "assistant",
      content: '', // To be filled after response
      loading: true,
      error: null,
      status: 'started', // 'started', 'pending', 'complete'
      parentId: userMessage.id, // Link to the message being replied to
      created: new Date().toISOString(),
      usage: null,
    };

    // Add the user message to the state first
    setMessages((prev) => [...prev, userMessage]);

    try {
      // Prepare context messages based on whether it's a reply
      let contextMessages;

      if (replyToMessageId) {
        contextMessages = getContextMessagesForReply(replyToMessageId);
        // Include the new user message at the end
        contextMessages.push(userMessage);
      } else {
        contextMessages = getLastFiveMessages();
        contextMessages.push(userMessage);
      }

      console.log("Sending contextMessages...", contextMessages);

      // Send context messages to the backend and get messageId
      const messageId = await instruct(contextMessages, modelName);
      console.log("Received messageId: ", messageId);

      // Add the placeholder model message
      setMessages((prev) => [...prev, modelMessage]);

      // Poll for the response
      const result = await pollForResult(messageId);
      console.log("Received result: ", result);

      // Update the model message with the actual response
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === modelMessage.id
            ? {
                ...msg,
                content: result.choices[0].content,
                loading: false,
                usage: result.usage,
                status: result.status,
                created: new Date(parseInt(result.created) * 1000).toISOString(), // Convert timestamp to ISO string
              }
            : msg
        )
      );

      // Reset reply target after replying
      setReplyToMessageId(null);
    } catch (err) {
      console.error("Error in handleSendMessage:", err);
      // Update the model message with the error
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === modelMessage.id
            ? { ...msg, error: err.message, loading: false }
            : msg
        )
      );
      setError('Failed to send instruction. Please try again.');
    } finally {
      setLoading(false);
      setMessage('');
    }
  };

  // Update the messageMap for quick lookup
  useEffect(() => {
    const map = new Map();
    messages.forEach(msg => {
      map.set(msg.id, msg);
    });
    setMessageMap(map);
  }, [messages]);

  // Auto-scroll to the latest message
  useEffect(() => {
    const container = document.getElementById('chat-container');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  // Function to toggle render mode
  const toggleRenderMode = () => {
    setRenderMode((prevMode) => (prevMode === 'linear' ? 'nested' : 'linear'));
  };

  // Helper function to build message tree (same as earlier)
  const buildMessageTree = (messages) => {
    const messageMap = new Map();
    const tree = [];

    // Initialize the map
    messages.forEach((msg) => {
      messageMap.set(msg.id, { ...msg, children: [] });
    });

    // Build the tree
    messages.forEach((msg) => {
      if (msg.parentId) {
        const parent = messageMap.get(msg.parentId);
        if (parent) {
          parent.children.push(messageMap.get(msg.id));
        } else {
          // If parent not found, treat as a root message
          tree.push(messageMap.get(msg.id));
        }
      } else {
        // Root messages
        tree.push(messageMap.get(msg.id));
      }
    });

    return tree;
  };

  // Build the message tree whenever messages change
  const messageTree = buildMessageTree(messages);

  return (
    <>
      {/* Toggle Button for Render Modes */}
      <Button onClick={toggleRenderMode} style={{ marginBottom: '1em' }}>
        Switch to {renderMode === 'linear' ? 'Nested' : 'Linear'} View
      </Button>

      <Segment id="chat-container" style={{ maxHeight: '500px', overflowY: 'auto', marginBottom: '1em' }}>
        <Comment.Group threaded={renderMode === 'nested'}>
          {renderMode === 'linear' ? (
            // Linear Rendering
            messages.map((msg) => {
              if (msg.role === 'system') {
                return (
                  <EditableSystemMessage
                    key={msg.id}
                    message={msg}
                    isEditing={isEditingSystem}
                    editedContent={editedSystemContent}
                    onEdit={handleEditSystem}
                    onSave={handleSaveSystem}
                    onCancel={handleCancelEditSystem}
                    onChange={setEditedSystemContent}
                  />
                );
              } else {
                return (
                  <ChatMessage
                    key={msg.id}
                    message={msg}
                    onReply={handleReply}
                  />
                );
              }
            })
          ) : (
            // Nested Rendering
            messageTree.map((msg) => {
              if (msg.role === 'system') {
                return (
                  <EditableSystemMessage
                    key={msg.id}
                    message={msg}
                    isEditing={isEditingSystem}
                    editedContent={editedSystemContent}
                    onEdit={handleEditSystem}
                    onSave={handleSaveSystem}
                    onCancel={handleCancelEditSystem}
                    onChange={setEditedSystemContent}
                  />
                );
              } else {
                return (
                  <NestedComment
                    key={msg.id}
                    message={msg}
                    onReply={handleReply}
                  />
                );
              }
            })
          )}
        </Comment.Group>
      </Segment>

      {/* Indicate if replying to a specific message */}
      {replyToMessageId && (
        <Segment clearing>
          <p>Replying to a message</p>
          <Button floated='right' size='small' onClick={() => setReplyToMessageId(null)}>
            Cancel
          </Button>
        </Segment>
      )}

      <Form onSubmit={handleSendMessage}>
        <Form.Field>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextArea
              placeholder={replyToMessageId ? "Replying to a message..." : "Enter your message..."}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ flex: 1, marginRight: '0.5em' }}
              rows={2}
            />
            <Button type="submit" loading={loading} disabled={!message.trim()} primary>
              Send
            </Button>
          </div>
        </Form.Field>
        {error && <Message error content={error} />}
      </Form>
    </>
  );
};

export default InstructInteraction;
