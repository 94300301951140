// ./components/models/ChatMessage.js
import React from 'react';
import { Comment, Icon, Loader, Message, Progress } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const getProgressPercent = (status) => {
  switch (status.toLowerCase()) {
    case 'started':
      return 20;
    case 'pending':
      return 60;
    case 'complete':
      return 100;
    default:
      return 0;
  }
};

const ChatMessage = ({ message, onReply }) => {
  const handleReplyClick = () => {
    onReply(message.id);
  };

  return (
    <Comment>
      <Comment.Content>

        {message.role === 'user' ? (
        <Comment.Author>
          <Icon name='user' color='blue' />{message.username}
        </Comment.Author>
        ) : (
        <Comment.Author>
          <Icon name='cube' color='green' />{"AI"}
        </Comment.Author>
        )}

        <Comment.Metadata>
          <span>{new Date(message.created).toLocaleString()}</span>
        </Comment.Metadata>

        {/* Reply Reference */}
        {message.parentId && (
          <Comment.Text>
            <strong>Replying to:</strong> {/* Optionally, display parent message snippet */}
          </Comment.Text>
        )}

        {/* Message Content */}
        {message.role === 'assistant' && message.loading && (
          <>
            <Loader active inline='centered' size='small' />
            <Progress
              percent={getProgressPercent(message.status)}
              indicating
              progress
              size='small'
              style={{ marginTop: '0.5em' }}
            >
              {message.status.charAt(0).toUpperCase() + message.status.slice(1)}
            </Progress>
          </>
        )}

        {message.role === 'assistant' && message.error && (
          <Message error content={message.error} />
        )}

        {!message.loading && !message.error && (
          <Comment.Text>{message.content}</Comment.Text>
        )}

        {/* Usage Metadata */}
        {message.role === 'assistant' && message.usage && !message.loading && !message.error && (
          <Comment.Metadata>
            <div>
              <strong>Usage:</strong> {message.usage.input_tokens}/{message.usage.output_tokens} tokens in {message.usage.duration.toFixed(2)}s
            </div>
          </Comment.Metadata>
        )}

        {/* Reply Button */}
        {message.role === 'assistant' && (
          <Comment.Actions>
            <Comment.Action onClick={handleReplyClick}>
              <Icon name='reply' /> Reply
            </Comment.Action>
          </Comment.Actions>
        )}
      </Comment.Content>
    </Comment>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['system', 'user', 'assistant']).isRequired,
    content: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    username: PropTypes.string,
    usage: PropTypes.shape({
      duration: PropTypes.number,
      inference: PropTypes.number,
      input_tokens: PropTypes.number,
      output_tokens: PropTypes.number,
    }),
    status: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.string,
    parentId: PropTypes.string, // For replies
  }).isRequired,
  onReply: PropTypes.func.isRequired,
};

export default ChatMessage;
