import EmbeddingInteraction from './EmbeddingInteraction';
import EmbeddingResponse from './EmbeddingResponse';
import InstructInteraction from './InstructInteraction';
import InstructResponse from './InstructResponse';
//import ImageInteraction from './ImageInteraction';
//import ImageResponse from './ImageResponse';
// Import other specialized components as needed...

export const modelComponents = {
  // FIXME: differentiate between image embedding and text embedding
  embed: {
    Interaction: EmbeddingInteraction,
    Response: EmbeddingResponse,
  },
  instruct: {
    Interaction: InstructInteraction,
    Response: InstructResponse,
  },
//  image: {
//    Interaction: ImageInteraction,
//    Response: ImageResponse,
//  },
  // Add more mappings as needed...
};
