import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Image, Label, Accordion, Transition } from 'semantic-ui-react';
import { fetchCollectionInfo } from '../services/collection';

import { squareLogo } from '../assets/assets_cdn';

const Sidebar = ({ currentUser, collections, collectionInfo, models, activeItem, setActiveItem, setSelectedCollectionId }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Menu vertical>
      <Menu.Item as={Link} to="/" name="Home" onClick={() => setActiveItem('home')}>
        <Image src={squareLogo} size="small" />
      </Menu.Item>

      <Menu.Item
        as={Link}
        to={currentUser ? "/user/account" : "/user/login"}
        name={currentUser ? currentUser.email : "login"}
        onClick={() => setActiveItem(currentUser ? "account" : "login")}
      >
        {currentUser ? currentUser.email : 'Login'}
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>Collections</Menu.Header>
        <Menu.Menu>
          {collections.map((collection_name) => (
            <Menu.Item
              key={collection_name}
              as={Link}
              to={`/collections/${collection_name}`}
              name={collection_name}
              active={activeItem === collection_name}
              onClick={async () => {
                setActiveItem(collection_name);
                setSelectedCollectionId(collection_name);
              }}
            >
              {collection_name}

              {collectionInfo && collectionInfo[collection_name] ? (
              <Label color='teal' style={{ marginLeft: '10px' }}>
                {collectionInfo[collection_name].count}
              </Label>
              ) : (
              <Label color='teal' style={{ marginLeft: '10px' }}>
                ?
              </Label>
              )}
            </Menu.Item>
          ))}
          <Menu.Item as={Link} to="/collections/create" name="New..." active={activeItem === 'create-collection'} onClick={() => setActiveItem('create-collection')} />
        </Menu.Menu>
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>Models</Menu.Header>
        <Menu.Menu>
      {Object.keys(models).map((modelType, index) => (
          <Menu.Item key={modelType}>
        <Accordion key={modelType}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={() => handleAccordionClick(index)}
          >
            <strong>{modelType.charAt(0).toUpperCase() + modelType.slice(1)}</strong>
          </Accordion.Title>
          <Transition visible={activeIndex === index} animation={activeIndex === index ? 'slide up' : 'slide down'} duration={300}>
          <Accordion.Content active={activeIndex === index}>
            <Menu.Menu>
              {Object.keys(models[modelType]).map((modelName) => (
                <Menu.Item
                  key={modelName}
                  as={Link}
                  to={`/models/${modelType}/${modelName}`}
                  name={modelName}
                  active={activeItem === modelName}
                  onClick={async () => {
                    setActiveItem(modelName);
                    setSelectedCollectionId(modelName);
                  }}
                >
                  {modelName}
                </Menu.Item>
              ))}
            </Menu.Menu>
          </Accordion.Content>
          </Transition>
        </Accordion>
          </Menu.Item>
      ))}
        </Menu.Menu>
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>API</Menu.Header>
        <Menu.Menu>
          <Menu.Item as={Link}  to="/docs/redocly.html">Database</Menu.Item>
          <Menu.Item as={Link}  to="/docs/redocly.html">Models</Menu.Item>
        </Menu.Menu>
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>Contact</Menu.Header>
        <Menu.Menu>
          <Menu.Item as="a" href="vec@bayis.co.uk"><Icon name="envelope" />vec@bayis.co.uk</Menu.Item>
          { /*
            <Menu.Item as="a" href="https://www.facebook.com/pages/PopStory/100094354340535/" target="_blank" rel="noopener noreferrer">
              <i className="facebook icon"></i> PopStory
            </Menu.Item>
            <Menu.Item as="a" href="https://www.instagram.com/popstorysupport/" target="_blank" rel="noopener noreferrer">
              <i className="instagram icon"></i> @popstorySupport
            </Menu.Item>
          */}
          <Menu.Item>
          <p>&copy; 2024</p>
          <p><a href="http://www.bayis.co.uk/" target="_blank" rel="noopener noreferrer">Bay Information Systems</a></p>
          <p>All Rights Reserved.</p>
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>

    </Menu>
  );
};

export default Sidebar;
