import React, { useEffect, useState } from 'react';
import { Button, Table, Header, Icon, Modal, Form, Message } from 'semantic-ui-react';
import { createUserApiKey, listUserApiKeys, deleteUserApiKey } from '../../services/users';

const UserAccount = ({ handleLogout }) => {
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newApiKey, setNewApiKey] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    setLoading(true);
    try {
      const keys = await listUserApiKeys();
      setApiKeys(keys);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const handleCreateApiKey = async () => {
    try {
      const apiKey = await createUserApiKey();
      setNewApiKey(apiKey);
      setShowModal(true);
      fetchApiKeys();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteApiKey = async (apiKey) => {
    try {
      await deleteUserApiKey(apiKey);
      fetchApiKeys();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Button as="Link" name="logout" onClick={handleLogout}>Logout</Button>

      <Header as="h2">Manage Your API Keys</Header>

      {error && <Message negative>{error}</Message>}

      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>API Key</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {apiKeys.map((key) => (
            <Table.Row key={key.api_key}>
              <Table.Cell>{key.api_key}</Table.Cell>
              <Table.Cell>{new Date(key.created_at * 1000).toLocaleString()}</Table.Cell>
              <Table.Cell>
                <Button color="red" onClick={() => handleDeleteApiKey(key.api_key)}>
                  <Icon name="trash" /> Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Button primary onClick={handleCreateApiKey}>
        <Icon name="plus" /> Create New API Key
      </Button>
    </div>
  );
};

export default UserAccount;
