import { Amplify } from 'aws-amplify'; // Use named import for Amplify

const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      region: process.env.REACT_APP_AWS_REGION,
    }
  }
};


console.log(amplifyConfig);


Amplify.configure(amplifyConfig);
