import React, { useState } from 'react';
import { Container, Header, Grid, Segment, List, Message, Button, Accordion, Form } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { modelComponents } from './ComponentMapping';

const ModelCard = ({ model }) => {
  const { modelType, modelName } = useParams(); // Extract type and name from the URL params
  const { Interaction, Response } = modelComponents[modelType] || {};

  const [activeIndex, setActiveIndex] = useState(-1);
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const handleSendMessage = async () => {
    // Simulate sending a message to the model and receiving a response
    // Replace this with the actual API call to your backend model
    setResponse(`Response for "${message}" from ${modelName}`);
    setMessage('');
  };

  return (
    <Container style={{ marginTop: '2em', marginBottom: '2em' }}>
      <Segment>
        {/* Section 1: Model Information */}
        <Header as='h2' dividing>
          {modelName}
        </Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as='h4'>Description</Header>
              <p>{model.description}</p>
              {model.provider && (
                <>
                  <Header as='h4'>Provider Information</Header>
                  <p><strong>Name:</strong> {model.provider.name}</p>
                  <p><strong>Description:</strong> {model.provider.description}</p>
                  {model.provider.links && (
                    <List>
                      {Object.entries(model.provider.links).map(([key, value]) => (
                        <List.Item key={key}>
                          <List.Content>
                            <strong>{key}:</strong> <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  )}
                </>
              )}
            </Grid.Column>

            {/* Section 2: Modalities */}
            <Grid.Column width={8}>
              <Header as='h4'>Modalities</Header>
              <List>
                <List.Item>
                  <strong>Inputs:</strong> {model.inputs.join(', ')}
                </List.Item>
                <List.Item>
                  <strong>Outputs:</strong> {model.outputs.join(', ')}
                </List.Item>
              </List>
              {model.parameters && (
                <>
                  <Header as='h4'>Parameters</Header>
                  <List>
                    {Object.entries(model.parameters).map(([key, value]) => (
                      <List.Item key={key}>
                        <strong>{key}:</strong> {value}
                      </List.Item>
                    ))}
                  </List>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* Section 3: Interactions */}
      <Segment>
        <Header as='h3'>Interact with {modelName}</Header>
        {Interaction && (
          <Interaction
            modelName={modelName}
            onSendData={handleSendMessage}
            message={message}
            setMessage={setMessage}
          />
        )}
        {Response && response && <Response response={response} />}
      </Segment>

      {/* Section 4: Testimonials */}
      <Segment>
        <Header as='h3' dividing>What Users Say</Header>
        { /* retrieve a list of tips and tricks about this model from the backend
             allow upvotes of the tips
             create a summary of tips which can be used in the description
             allow users to ask questions and put the comments in as RAG to a chat bot
          */ }
        {model.testimonials && model.testimonials.length > 0 ? (
          model.testimonials.map((testimonial, index) => (
            <Message key={index} info>
              <Message.Content>
                <p>"{testimonial.quote}"</p>
                <Message.Header>{testimonial.author}</Message.Header>
              </Message.Content>
            </Message>
          ))
        ) : (
          <Message>No comments available.</Message>
        )}
        { /* add a new comment... */ }
      </Segment>

      {/* Section 5: Call to Action */}
      <Segment>
        <Header as='h3'>Attach to pipeline</Header>
        { /* a drop down list of the users pipelines (with a "new..." option)
             on click, move to the pipeline page and add this model and the interactions as a step at the end of the pipeline
        */ }
        <Message>No pipelines available.</Message>
      </Segment>
    </Container>
  );
};

export default ModelCard;
