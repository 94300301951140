import React, { useState } from 'react';
import { signIn } from 'aws-amplify/auth';
import { Form, Button, Message } from 'semantic-ui-react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const { isSignedIn } = await signIn({ username, password });
      // Redirect or perform additional actions after successful login
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          label='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <Form.Input
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <Message negative>{error}</Message>}
        <Button type='submit'>Login</Button>
      </Form>
    </div>
  );
};

export default Login;
