import { apiDbRequest } from './api';

export const fetchCollections = async () => {
  return await apiDbRequest('', { method: 'GET', requireAuth: true });
};

export const createCollection = async (collectionName, embeddingSize) => {
  const body = {
    name: collectionName,
    embedding_vector_length: embeddingSize,
  };
  return await apiDbRequest(`/${collectionName}`, { method: 'POST', body });
};

export const fetchCollectionInfo = async (collectionName) => {
  return await apiDbRequest(`/${collectionName}`, { method: 'GET' });
};

export const deleteCollection = async (collectionName) => {
  return await apiDbRequest(`/${collectionName}`, { method: 'DELETE' });
};

export const fetchRandomItems = async (collectionName) => {
  console.log("fetching: ", collectionName);
  return await apiDbRequest(`/${collectionName}/random`, { method: 'GET' });
};

export const searchCollection = async (collectionName, embedding) => {
  // search by embedding vector only
  const body = {
    embedding: embedding
  };

  console.log("searching: ", collectionName);
  return await apiDbRequest(`/${collectionName}/search`, { method: "POST", body });
}

export const fetchCollectionItem = async (collectionName, itemName) => {
  return await apiDbRequest(`/${collectionName}/item/${itemName}`, { method: "GET" });
}

export const fetchCollectionEmbedding = async (collectionName, itemName) => {
  return await apiDbRequest(`/${collectionName}/embedding/${itemName}`, { method: "GET" });
}
