import React, { useState, useEffect } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { pollForResult } from '../../services/text_embed'; // Import the polling function

const EmbeddingResponse = ({ messageId }) => {
  const [response, setResponse] = useState(null); // The backend response
  const [loading, setLoading] = useState(true); // Loading state for this component
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true; // To prevent state updates if component is unmounted

    const fetchResponse = async () => {
      try {
        const result = await pollForResult(messageId); // Polling function

        if (isMounted) {
          setResponse(result);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchResponse();

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, [messageId]);

  if (loading) {
    return <Loader active inline size="small" />; // Show loader while waiting
  }

  if (error) {
    return <Message error>{error}</Message>; // Show error message if there's an error
  }

  if (!response) {
    return null; // Or you could display a placeholder
  }

  // Destructure the response object for easier access
  const { model, embedding, usage, status } = response;

  return (
    <div>
      <p>
        <strong>Model:</strong> {model}
      </p>
      <p>
        <strong>Embedding:</strong> {JSON.stringify(embedding)}
      </p>
      <p>
        <strong>Usage:</strong> {JSON.stringify(usage)}
      </p>
      <p>
        <strong>Status:</strong> {status}
      </p>
    </div>
  );
};

export default EmbeddingResponse;
