import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Statistic } from 'semantic-ui-react';

import { fetchCollectionInfo, fetchCollectionItem, fetchRandomItems } from '../services/collection';

import CollectionItems from './CollectionItems';
import CollectionSearch from './CollectionSearch';

const CollectionDetails = () => {
  const { collectionId } = useParams();
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [items, setItems] = useState([]);

  const getCollectionItem = async (itemName) => {
    try {
      const item = await fetchCollectionItem(collectionId, itemName);
      console.log("item: ", item);
    } catch (error) {
      console.error("Failed to fetch item: ", error);
    }
  }

  const getRandomSelection = async () => {
    try {
      const newItems = await fetchRandomItems(collectionId);
      setItems(newItems.response || []);
    } catch (error) {
      console.error('Failed to fetch random items:', error);
    }
  };

  useEffect(() => {
    const loadCollectionInfo = async () => {
      try {
        const info = await fetchCollectionInfo(collectionId);
        console.log("info :", info);
        setCollectionInfo(info);
      } catch (error) {
        console.error('Failed to load collection info:', error);
      }
    };

    loadCollectionInfo();
  }, [collectionId]);

  return (
    <>
      <h2>{collectionId} Items</h2>
      <Statistic.Group>
        <Statistic label="Items" value={ collectionInfo?.count } />
      </Statistic.Group>

      <Button onClick={getRandomSelection}>Get Random Items</Button>
      {items.length > 0 && (
        <CollectionItems items={items} collectionId={collectionId} />
      )}

      <h2>{collectionId} Search</h2>
      <CollectionSearch collectionId={collectionId} />
    </>
  );
};

export default CollectionDetails;
