import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Container, Menu, Grid, Header, Image, Label } from 'semantic-ui-react';

import './config/amplifyConfig';

import { fetchCollections, fetchCollectionInfo } from './services/collection';
import CreateCollection from './components/CreateCollection';
import CollectionDetails from './components/CollectionDetails';
import Sidebar from './components/Sidebar';

import { ModelOverview } from './components/models';
import UserAccount from './components/users/Account';

import { getCurrentUser, login, logout } from './services/auth';
import { Signup, Login, ForgotPassword } from './components/auth';

import { DEFAULT_CONFIG } from './config';
import { squareLogo } from './assets/assets_cdn';
import './App.css';

import LandingPage from './LandingPage';


const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [collections, setCollections] = useState([]);
  const [collectionCounts, setCollectionCounts] = useState({});
  const [config, setConfig] = useState(DEFAULT_CONFIG);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    const checkUserAuthentication = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          setCurrentUser(user);
        }
      } catch (error) {
        console.error('User authentication:', error);
      }
    };

    checkUserAuthentication();
  }, []);

  useEffect(() => {
    const loadCollections = async () => {
      try {
        const collections = await fetchCollections(config);
        setCollections(collections);

        // Fetch item counts for each collection
        const counts = await Promise.all(collections.map(async (collection) => {
          const info = await fetchCollectionInfo(collection);
          return { [collection]: info };
        }));

        // Convert counts array to an object for easy access
        const countsObj = counts.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setCollectionCounts(countsObj); // Update state with item counts
      } catch (error) {
        console.error('Failed to fetch collections:', error);
      }
    };

    loadCollections();
  }, [DEFAULT_CONFIG.endpoints.database]);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  const handleLogout = async () => {
    try {
      await logout();
      setCurrentUser(null);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Router>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Sidebar
              currentUser={currentUser}
              collections={collections}
              collectionInfo={collectionCounts}
              models={config.models}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              setSelectedCollectionId={setSelectedCollectionId}
            />
          </Grid.Column>

          <Grid.Column width={12}>
            <Container>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/collections/create" element={<CreateCollection />} />
                <Route path="/collections/:collectionId" element={<CollectionDetails />} />
                <Route path="/models/:modelType/:modelName" element={<ModelOverview models={config.models} />} />

                <Route path="/user/signup" element={<Signup />} />
                <Route path="/user/login" element={<Login />} />
                <Route path="/user/forgot-password" element={<ForgotPassword />} />
                <Route path="/user/account" element={<UserAccount handleLogout={ handleLogout } />} />
              </Routes>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Router>
  );
};

export default App;
