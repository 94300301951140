import React, { useState } from 'react';
import { Form, Button, Input, Segment, Loader, Message } from 'semantic-ui-react';
import { embedText, pollForResult } from '../services/text_embed';
import { searchCollection } from '../services/collection';
import CollectionItems from './CollectionItems';

const CollectionSearch = ({ collectionId }) => {
  const [query, setQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearching(true);
    setError(null);
    setStatusMessage('Embedding query...');
    try {
      const messageId = await embedText(query);
      setStatusMessage('Waiting for embedding to be ready...');
      const embeddingResponse = await pollForResult(messageId);
      const embedding = embeddingResponse.embedding;
      if (!embedding) {
        throw new Error('Embedding not found in response');
      }
      setStatusMessage('Searching collection...');
      const searchResults = await searchCollection(collectionId, embedding);
      setResults(searchResults.articles || []);
      setStatusMessage('Search completed');
    } catch (err) {
      setError(err.message);
      setStatusMessage('');
    } finally {
      setSearching(false);
    }
  };

  return (
    <Segment>
      <Form onSubmit={handleSearch}>
        <Form.Field>
          <Input
            type='text'
            icon='search'
            iconPosition='right'
            placeholder='Search...'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            required
          />
        </Form.Field>
      </Form>
      {searching && (
        <div>
          <Loader active inline size='small' />
          <span> {statusMessage}</span>
        </div>
      )}
      {error && <Message error>{error}</Message>}
      {!searching && results.length > 0 && (
        <CollectionItems items={results} collectionId={collectionId} />
      )}
    </Segment>
  );
};

export default CollectionSearch;
