import React from 'react';
import { Comment, Icon, Button, Input, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const EditableSystemMessage = ({
  message,
  isEditing,
  editedContent,
  onEdit,
  onSave,
  onCancel,
  onChange,
}) => {
  return (
    <Comment>
      <Comment.Avatar>
        <Icon name='cogs' size='big' color='grey' />
      </Comment.Avatar>
      <Comment.Content>
        <Comment.Author as='a'>System</Comment.Author>
        <Comment.Metadata>
          <span>{new Date(message.created).toLocaleString()}</span>
        </Comment.Metadata>
        {isEditing ? (
          <Form>
            <Form.Field>
              <Input
                value={editedContent}
                onChange={(e) => onChange(e.target.value)}
                fluid
              />
            </Form.Field>
            <Button size='small' primary onClick={onSave} style={{ marginRight: '0.5em' }}>
              Save
            </Button>
            <Button size='small' onClick={onCancel}>
              Cancel
            </Button>
          </Form>
        ) : (
          <>
            <Comment.Text>{message.content}</Comment.Text>
            <Comment.Actions>
              <Comment.Action onClick={onEdit}>
                <Icon name='edit' /> Edit
              </Comment.Action>
            </Comment.Actions>
          </>
        )}
      </Comment.Content>
    </Comment>
  );
};

EditableSystemMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['system']).isRequired,
    content: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    username: PropTypes.string,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  editedContent: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditableSystemMessage;
