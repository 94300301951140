import React, { useState } from 'react';
import { Segment, Button, Loader, Message, Item, Icon } from 'semantic-ui-react';
import { searchCollection, fetchCollectionEmbedding } from '../services/collection';

const CollectionItem = ({ item, collectionId }) => {
  const [showSimilar, setShowSimilar] = useState(false);
  const [loadingSimilar, setLoadingSimilar] = useState(false);
  const [similarItems, setSimilarItems] = useState([]);
  const [error, setError] = useState(null);
  const [showText, setShowText] = useState(false);

  const handleViewSimilar = async () => {
    setShowSimilar(!showSimilar);

    if (!showSimilar && similarItems.length === 0) {
      // find similar items by getting the embedding vector for this item and using that as a search query
      setLoadingSimilar(true);
      setError(null);

      try {
        // Get the embedding for the item
        const itemEmbedding = await fetchCollectionEmbedding(collectionId, item.metadata.id);

        if (!itemEmbedding) {
          throw new Error('Embedding not found for item');
        }

        // Search for similar items using the embedding
        const results = await searchCollection(collectionId, itemEmbedding.embedding);
        setSimilarItems(results.articles || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingSimilar(false);
      }
    }
  };

  // Helper function to determine which icon to display for the "Find Similar" button
  const getSimilarButtonIcon = () => {
    if (loadingSimilar) {
      return <Loader active inline size="small" />;
    } else if (similarItems.length > 0) {
      return showSimilar ? <Icon name="arrow up" /> : <Icon name="arrow down" />;
    } else {
      return <Icon name="fork" />;
    }
  };

  const handleShowText = async () => {
    setShowText(!showText);
  };

  console.log("similar.items.length: ", similarItems.length);

  return (
    <>
      <Item>
        <Item.Content>
          <Item.Header>{ item.metadata?.headline || item.metadata?.title }</Item.Header>
          { showText && item.metadata?.text && <Item.Meta>{ item.metadata?.text }</Item.Meta> }
          <Item.Extra>{ item.metadata.id }</Item.Extra>
          {error && <Message error>{error}</Message>}
        </Item.Content>

        <Button.Group icon vertical floated="right">
          <Button icon onClick={handleShowText}>
            {showText ? <Icon name="minus" /> : <Icon name="plus" />}
          </Button>
          <Button icon onClick={handleViewSimilar}>
            {getSimilarButtonIcon()}
          </Button>
        </Button.Group>
      </Item>
      { similarItems.length > 0 && (
        <Segment>
        {showSimilar && (
          <Item.Group divided>
          {similarItems.map((similarItem, index) => (
            <CollectionItem
              key={similarItem.metadata.id}
              item={similarItem}
              collectionId={collectionId}
            />
          ))}
          </Item.Group>
        )}
        </Segment>
      )}
    </>
  );
};

export default CollectionItem;
