// src/components/auth/ForgotPassword.js
import React, { useState } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { Form, Button, Message } from 'semantic-ui-react';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleRequestCode = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    try {
      await resetPassword({ username });
      setStep(2);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    try {
      await confirmResetPassword({ username, confirmationCode: code, newPassword });
      setSuccess(true);
      setStep(1); // Reset step to 1 for future requests
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      {step === 1 ? (
        <Form onSubmit={handleRequestCode}>
          <Form.Input
            label='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          {error && <Message negative>{error}</Message>}
          <Button type='submit'>Request Code</Button>
        </Form>
      ) : (
        <Form onSubmit={handleSubmitNewPassword}>
          <Form.Input
            label='Verification Code'
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <Form.Input
            label='New Password'
            type='password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          {error && <Message negative>{error}</Message>}
          {success && <Message positive>Password reset successful!</Message>}
          <Button type='submit'>Reset Password</Button>
        </Form>
      )}
    </div>
  );
};

export default ForgotPassword;
